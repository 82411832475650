import React from "react";

const Workplace = () => {
  return (
    <>
      <section className="workplace" id="first">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <div className="left-content">
                <p>
                  At the heart of AiMining Technologies is a team of world-class AI experts who are redefining  the boundaries of what's possible.
                  Our researchers, engineers, and data scientists are not  only masters of their craft,
                  but they are also driven by a steadfast commitment to  developing AI solutions that are secure, ethical,
                  and truly transformative.
                </p>
                <p>
                  Guided by the principles of Secure by Design, Trustworthy AI, Zero Trust, and industry leading standards like NIST, ISO,
                  and IEEE, we have created a culture of innovation that  never compromises on security or ethics.
                  Our team approaches every challenge with a  deep understanding of the potential risks and a relentless determination to find solutions
                  that put the well-being of our users and society at large first.
                </p>
                <p>
                  In this dynamic environment, you'll have the opportunity to collaborate with some of the  brightest minds in the field,
                  working on projects that have the power to reshape entire  industries.
                  Whether you're developing cutting-edge AI models, designing next-generation  semiconductor chips, or architecting flexible cloud-based infrastructure,
                  your  contributions will have a direct and meaningful impact on the future of responsible AI.
                </p>
                <img src="/images/company1.png" />
              </div>
            </div>
            <div className="col-sm-12 col-lg-6 text-end">
              <div className="right-content">
                {/* <img src="/images/company2.png" /> */}
                <img src="/images/team.png" />
                <h1>
                  Cultivating a Diverse and Inclusive Workplace
                </h1>
                <p>
                  At AiMining Technologies, we believe that diversity is the key to unlocking the full potential  of AI.
                  That's why we've built a team that reflects the rich tapestry of backgrounds,  perspectives, and experiences from around the world.
                </p>
                <p>
                  By embracing diversity and inclusion, we foster an environment where everyone can thrive.
                  Our employees are encouraged to challenge the status quo, share their unique insights,
                  and collaborate in ways that push the boundaries of what's possible. This collaborative  spirit,
                  combined with our commitment to continuous learning and development, creates a  dynamic and
                  supportive workplace where you can truly grow and excel.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Workplace;
