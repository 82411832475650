import Hero from "./section/Hero";
import BlogSection from "../Home/BlogSection";

function News() {
    return (
        <>
            <Hero />
            <BlogSection />
        </>
    )
}

export default News;