import React from "react";

const Hero = () => {
  return (
    <div>
      <div className="hompage-hero" id="zIndexMob">
        <div className="home-inner">
          <div className="hero-section-new">
            <div id="homepage-bg-blur-anim">
              <div className="hero-blur-bg blur-jelly"></div>
              <div className="hero-blur-bg blur-icee"></div>
              <div className="hero-blur-bg blur-gumdrop"></div>
            </div>
          </div>
          <div className="container vertical main-hero">
            <div
              className="row aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="col-lg-12 text-center">
                <h1>We believe that AI has the</h1>
                <h2>potential to transform  industries</h2>
                <h2>and improve the lives of people</h2>
                <h2>around  the world</h2>
                <p className="mt-4">
                  At AiMining Technologies, we specialize in AI model development with a focus on AI  sovereignty. Our team of AI experts has a deep<br />
                  understanding of the latest AI models and  standards, and we are dedicated to building AI models that are responsible, trustworthy, and<br />
                  reliable, with privacy and data privacy at the core of our business.
                </p>
                {/* <div className="text-center gap-2 d-flex justify-content-center mt-5">
                  <button className="create-button">GET START</button>
                  <button className="wallet-button">LEARN MORE</button>
                </div> */}
                <div
                  className="d-flex align-items-center justify-content-between animated"
                  style={{ paddingBottom: "100px" }}
                ></div>
              </div>
            </div>
            {/* <CompanySlider /> */}

          </div>
        </div>
        <div className="patch">
          <img src="/images/patch.png" alt="patch" />
        </div>
      </div>

    </div>
  );
};

export default Hero;
