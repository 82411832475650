import React from "react";

const Flexible = () => {
  return (
    <section className="Flexible">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <div className="left-content">
              <div class="heading">
                <h4>
                  {" "}
                  Flexible AI Infrastructure<span>3</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12">
            <div className="center-content">
              <h4 class="purple-color mb-4">Compute-as-a-Service:</h4>
              <p>
                We offer a flexible, on-demand computing platform that provides
                businesses with access to the raw computational power required
                for complex AI training and inference tasks. Our
                Compute-as-a-Service model eliminates the need for costly,
                inhouse hardware investments, enabling rapid scalability and
                cost optimization.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12">
            <div className="center-content">
              <h4 class="blue-color mb-4">Training-as-a-Service:</h4>
              <p>
                Leveraging our expert data science and AI engineering teams, we
                provide a comprehensive machine learned Training-as-a-Service
                solution. This allows our clients to focus on their core
                business objectives while we handle the entire AI model
                development lifecycle, from data preparation to model
                deployment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Flexible;
