import React from 'react'

const Pioneer = () => {
  return (
    <section className='Pioneer'>
      <div className="container">
        <div className='row'>
          <div className='col-lg-3 col-md-6 col-sm-12'>
            <div className='left-content'>
              <img src="/images/pioneerleft.svg" className='w-100' />
            </div>
          </div>
          <div className='col-lg-6 col-sm-12'>
            <div className='center-content'>
              <div className='heading'>
                <h4>
                  Pioneering AI Semiconductor Design
                  <span class="two">2</span>
                </h4>
              </div>
              <div className='content'>
                <h4 className='purple-color'>
                  AI-Optimized Chips:&nbsp;
                  <span>
                    Our in-house semiconductor design team is at the forefront of  developing cutting-edge AI accelerators and processors.
                    These custom-built TPU and NPU  chips are engineered to power the most demanding AI workloads,
                    delivering unrivaled  performance and energy efficiency.
                  </span>
                </h4>
                <hr />
                <h4 className='blue-color text-end'>
                  Scalable Hardware Infrastructure:&nbsp;
                  <span>
                    From edge devices to cloud-based systems, our  semiconductor solutions are designed to seamlessly scale to meet the growing  computational demands of AI.
                    This allows our customers to future-proof their  infrastructure and adapt to evolving AI requirements.
                  </span>
                </h4>
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12'>
            <div className='right-content'>
              <img src="/images/pioneerright.svg" className='w-100' />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Pioneer