import React from 'react'

const Mission = () => {
  return (
    <section className='Mission'>
      <div className="container">
        <div className='row'>
          <div className='col-lg-12'>
            <div className='content'>
              <h4>
                <span className='purple-color'>Join Our</span> Mission to Forge the Future of Trustworthy AI
              </h4>
              <p>
                At AiMining Technologies, we're building a team of the brightest minds in AI, united by a
                shared passion for developing innovative, secure, and trustworthy solutions.
              </p>
              <p>
                Meet our talented team of experienced AI researchers, engineers,
                and data scientists. They  are pioneers in their fields, driven by a relentless pursuit of technological
                breakthroughs  that can positively transform industries and communities.
              </p>
              <p>
                Together, we are redefining the boundaries of what's possible in AI,
                creating cutting-edge  solutions that not only deliver unparalleled performance but also uphold the highest
                standards of trust , security and responsibility.
              </p>
              <p>
                If you share our vision of harnessing AI for the greater good, we invite you to join our  mission.
                Become a part of the AiMining Technologies team and help shape the future of  responsible, high-impact AI.
              </p>
              <p>
                Explore our open positions and take the first step towards a rewarding career at the  forefront of AI innovation.
              </p>
              <div class="input-group custom-input mb-3">
                <input type="text" class="form-control" placeholder="Email." />
                  <div class="input-group-append">
                    <button class="header-button" type="button">Join Us</button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Mission;