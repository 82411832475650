import React from "react";

const Hero = () => {
  return (
    <div>
      <div className="hompage-hero contact" id="zIndexMob">
        <div className="container">
          <div
            className="row aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <div className="col-lg-12 text-center">
              <h1>GET IN TOUCH</h1>
            </div>
          </div>

        </div>
      </div>

    </div>
  );
};

export default Hero;
