import React, { useRef } from "react";
import Cardsmain from "../common/CardsMain";
import Technology from "../Home/Technology";
import Values from "../Home/Values";
import MakeImpact from "../common/MakeImpact";
import BlogSection from "../Home/BlogSection";
import GetInTouch from "../Home/GetInTouch";
import Hero from "./section/Hero";
import CompanySlider from "../common/CompanySlider";
import Partner from "../common/Partner";
const Technologiess = () => {
  const valuesRef = useRef(null); // Reference to the Values component

  const scrollToValues = () => {
    // Scroll to the Values component
    valuesRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      {/* <div className="hompage-hero" id="zIndexMob">
        <div className="home-inner">
          <div className="hero-section-new">
            <div id="homepage-bg-blur-anim">
              <div className="hero-blur-bg blur-jelly"></div>
              <div className="hero-blur-bg blur-icee"></div>
              <div className="hero-blur-bg blur-gumdrop"></div>
            </div>
          </div>
          <div className="container main-hero">
            <div
              className="row aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="col-lg-12 text-center">
                <span>Empowering Progress:</span>
                <h1>Developing Human-Centered </h1>
                <h2>AI Solutions </h2>
                <p className="mt-4">
                  Building trustworthy, reliable, and private AI systems for a
                  better tomorrow.
                </p>
                <div className="text-center gap-2 d-flex justify-content-center mt-5">
                  <button className="create-button">GET START</button>
                  <button className="wallet-button">LEARN MORE</button>
                </div>
                <div
                  className="d-flex align-items-center justify-content-between animated"
                  style={{ paddingBottom: "100px" }}
                ></div>
              </div>
            </div>
            <div class="toggle pb-3" onClick={scrollToValues}>
              <div class="toggle-button">
                <div class="ball"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="patch-tec">
        <img src="/images/patch.png" alt="patch" />
      </div>
      </div> */}
      <Hero />
      <Partner />
      {/* <CompanySlider /> */}

      <section className="Models technologies container" id="first">
        <div className="Heading">
          <div>
            <h2>AI Models and Core Applications</h2>
          </div>
          <div>
            <p>
              AI models are sophisticated algorithms designed to simulate human
              intelligence in various tasks. They leverage large datasets to
              learn patterns and make predictions or decisions without explicit
              programming.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-6">
            <div className="emp-img mb-4 mb-md-0">
              <img src="/images/frontier.png" alt="education" />
            </div>
          </div>
          <div className="offset-lg-1 col-lg-6 col-md-6 pt-lg-5 pt-0">
            <h3 className="pb-3 title">Frontier Performance:</h3>
            <p className="desc">
              Our AI models are meticulously designed and trained to push the
              boundaries of what's possible in terms of speed, accuracy, and
              reasoning capabilities. We leverage advanced machine learning
              techniques, including large language models, computer vision, and
              predictive analytics, to create highly performant solutions that
              can tackle complex, real-world challenges.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 pt-lg-5 pt-0 order-2 order-md-1 mt-3 mt-md-0">
            <h3 className="pb-3 title">Vertical-Centric AI Solutions:</h3>
            <p className="desc">
              Understanding that one-size-fits-all AI solutions often fall
              short, we have developed a portfolio of industry-specific AI
              applications. For example, in healthcare, our models can assist
              with early disease detection, personalized treatment planning, and
              clinical decision support. In the education sector, our AI-powered
              tools enhance personalized learning, improve learning outcomes,
              and empower teachers.
            </p>
          </div>
          <div className="offset-lg-1 col-lg-5 col-md-6 order-1 order-md-2">
            <div className="emp-img-2">
              <img src="/images/vertical.png" alt="healtcare" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-5 col-md-6">
            <div className="emp-img mb-4 mb-md-0">
              <img src="/images/ethical.png" alt="education" />
            </div>
          </div>
          <div className="offset-lg-1 col-lg-6 col-md-6 pt-lg-5 pt-0">
            <h3 className="pb-3 title">Ethical and Responsible AI:</h3>
            <p className="desc">
              At the core of our AI models and applications is a steadfast
              commitment to ethics, privacy, and transparency. We employ
              techniques like differential privacy and explainable AI to ensure
              our solutions are trustworthy, secure, and aligned with the best
              interests of our clients and end-users
            </p>
          </div>
        </div>
      </section>
      <section className="Models technologies container">
        {/* <div className="Heading">
          <div>
            <h2>Pioneering Semiconductor Design</h2>
          </div>
          <div>
            <p>
              Lorem ipsum dolor sit amet consectetur. Massa aliquet cursus habitant odio.
              Sit turpis facilisis mauris suspendisse. Diam facilisis venenatis curabitur morbi potenti proin at eget.
              Turpis ultrices at amet morbi at amet molestie magna augue.
            </p>
          </div>
        </div> */}
        <div className="row">
          <div className="col-lg-6 col-md-6 pt-lg-5 pt-0 order-2 order-md-1 mt-3 mt-md-0">
            <h3 className="pb-3 title">Scalable Hardware Infrastructure:</h3>
            <p className="desc">
              Our semiconductor solutions are designed to seamlessly scale from
              edge devices to high-performance cloud infrastructure. This
              scalability allows our clients to deploy AI-powered applications
              across a wide range of environments, from IoT-enabled smart
              devices to large-scale enterprise systems, without sacrificing
              performance or reliability.
            </p>
          </div>
          <div className="offset-lg-1 col-lg-5 col-md-6 order-1 order-md-2">
            <div className="emp-img-2">
              <img src="/images/scalable.png" alt="healtcare" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-5 col-md-6">
            <div className="emp-img mb-4 mb-md-0">
              <img src="/images/hardware.png" alt="education" />
            </div>
          </div>
          <div className="offset-lg-1 col-lg-6 col-md-6 pt-lg-5 pt-0">
            <h3 className="pb-3 title">Hardware-Software Co-optimization:</h3>
            <p className="desc">
              By tightly integrating our AI models and applications with our
              proprietary semiconductor designs, we achieve unprecedented levels
              of optimization and efficiency. This hardware-software co-design
              approach ensures that our clients can fully harness the power of
              our AI technology, regardless of their specific hardware
              requirements or deployment scenarios.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 pt-lg-5 pt-0 order-2 order-md-1 mt-3 mt-md-0">
            <h3 className="pb-3 title">AI-Optimized Chips:</h3>
            <p className="desc">
              Recognizing the need for specialized hardware to power the most
              demanding AI workloads, our semiconductor team has developed a
              suite of cutting edge AI accelerators and processors. These
              custom-designed chips leverage advanced architectures, such as
              tensor processing units (TPUs) and neuromorphic computing, to
              deliver unparalleled performance and energy efficiency.
            </p>
          </div>
          <div className="offset-lg-1 col-lg-5 col-md-6 order-1 order-md-2">
            <div className="emp-img-2">
              <img src="/images/chip.png" alt="healtcare" />
            </div>
          </div>
        </div>
      </section>

      <section className="Models technologies Flexible-content container">
        <div className="Heading">
          <div>
            <h2>Flexible AI Infrastructure</h2>
          </div>
          <div>
            <p>
              Flexible AI infrastructure refers to a versatile framework
              designed to accommodate various AI applications and workloads
              while adapting to changing requirements and environments. It
              enables seamless integration of diverse AI technologies, such as
              machine learning models, natural language processing, computer
              vision, and more, into existing systems or workflows.
            </p>
          </div>
        </div>
        <div className="content">
          <div className="left-content">
            <h4 className="purple-color">Compute-as-a-Service:</h4>
            <p>
              Recognizing the significant investments required to build and
              maintain high-performance AI infrastructure, we offer a
              Compute-as-a-Service model that provides on-demand access to the
              necessary computational resources. Our clients can seamlessly
              scale their AI workloads up or down as needed, without the burden
              of managing complex hardware and IT operations.
            </p>
            <div className="image">
              <div>
                <img src="/images/computer.svg" />
              </div>
              <div>
                <p>
                  Recognizing the significant investments required to build and
                  maintain high-performance AI infrastructure,
                </p>
              </div>
            </div>
          </div>
          <div className="center-content">
            <img src="/images/lock.svg" />
            <div className="text">
              <h4 className="blue-color">
                Secure and Compliant Infrastructure:
              </h4>
              <p>
                Recognizing the importance of data privacy and security, we have
                designed our AI infrastructure to meet the most stringent
                compliance standards, including industry-specific regulations
                and data privacy frameworks. Our clients can rest assured that
                their sensitive data and AI models are safeguarded, even when
                leveraging our Compute-as-a-Service and Training-as-a Service
                offerings.
              </p>
            </div>
          </div>
          <div className="right-content">
            <h4 className="purple-color">Training-as-a-Service:</h4>
            <p>
              Developing effective AI models and applications requires
              specialized expertise, data, and computational resources. Our
              Training-as-a-Service offering leverages the deep knowledge and
              capabilities of our data science and AI engineering teams to
              handle the entire model development lifecycle on behalf of our
              clients. This allows them to focus on their core business
              objectives while we handle the technical complexities of AI model
              training and deployment.
            </p>
            <div className="image">
              <div>
                <img src="/images/compute.svg" />
              </div>
              <div>
                <p>
                Developing effective AI models and applications requires
              specialized expertise, data, and computational resources.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div ref={valuesRef}>

        <Cardsmain />
      </div> */}
      {/* <Technology /> */}
      {/* <Values /> */}
      <MakeImpact />
      {/* <BlogSection /> */}
      {/* <GetInTouch /> */}
    </div>
  );
};

export default Technologiess;
