function Career() {
  return (
    <>
      <div className="hompage-hero contact" id="zIndexMob">
        <div className="container">
          <div
            className="row aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <div className="col-lg-12 text-center">
              <h1>Build your Career with us</h1>
            </div>
          </div>
        </div>
      </div>
      <section className="career">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="content">
                <p>
                  Join Our Mission to Forge the Future of Trustworthy AI At
                  AiMining Technologies, we're building a team of the brightest
                  minds in AI, united by a shared passion for developing
                  innovative, secure, and trustworthy solutions.
                </p>
                <p>
                  Meet our talented team of experienced AI researchers,
                  engineers, and data scientists. They are pioneers in their
                  fields, driven by a relentless pursuit of technological
                  breakthroughs that can positively transform industries and
                  communities.
                </p>
                <p>
                  Together, we are redefining the boundaries of what's possible
                  in AI, creating cutting-edge solutions that not only deliver
                  unparalleled performance but also uphold the highest standards
                  of trust , security and responsibility.
                </p>
                <p>
                  If you share our vision of harnessing AI for the greater good,
                  we invite you to join our mission. Become a part of the
                  AiMining Technologies team and help shape the future of
                  responsible, high-impact AI.
                </p>
                <p>
                  Explore our open positions and take the first step towards a
                  rewarding career at the forefront of AI innovation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Career;
