import React, { useRef } from 'react'
import Hero from './section/Hero'
import CompanySlider from '../common/CompanySlider'
import Empowering from '../Home/Empowering';
import Workplace from './section/Workplace';
import Mission from './section/Mission';
import TeamWork from '../Home/TeamWork'
import Values from '../Home/Values'
import BlogSection from '../Home/BlogSection'
import GetInTouch from '../Home/GetInTouch'
import Partner from '../common/Partner';

const Company = () => {
  const valuesRef = useRef(null); // Reference to the Values component

  const scrollToValues = () => {
    // Scroll to the Values component
    valuesRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <Hero />
      {/* <CompanySlider /> */}
      <Partner />
      <Workplace />
      <Mission />
      {/* <div ref={valuesRef}>

        <Empowering />
      </div> */}
      {/* <TeamWork /> */}
      {/* <Values /> */}
      {/* <div>
        <img className='w-100' src="/images/aisection.png" alt="aisection" />
      </div> */}
      {/* <BlogSection /> */}
      {/* <GetInTouch /> */}
    </div>
  )
}

export default Company