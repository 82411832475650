import React from "react";
import Slider from "react-slick";
const CompanySlider = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="CompanySlider">
        <div className="container" style={{ padding: "0px 50px" }}>
          <div className="row">
            <div className="col-12">
              <div className="text-center mb-4">
                <h4>Join 4,000+ companies already growing</h4>
              </div>
            </div>
            <div className="col-12">
              <Slider {...settings}>

                <div>
                  <img src="/images/slider/bolt.svg" />
                </div>
                <div>
                  <img src="/images/slider/light.svg" />
                </div>
                <div>
                  <img src="/images/slider/feather.svg" />
                </div>
                <div>
                  <img src="/images/slider/global.svg" />
                </div>
                <div>
                  <img src="/images/slider/niet.svg" />
                </div>
                <div>
                  <img src="/images/slider/bolt.svg" />
                </div>


                {/* <div className="text-center">
                  <img src="/images/Fictional company logo.png" />
                </div>
                <div className="text-center">
                  <img src="/images/Fictional company logo.png" />
                </div>
                <div className="text-center">
                  <img src="/images/Fictional company logo.png" />
                </div>
                <div className="text-center">
                  <img src="/images/Fictional company logo.png" />
                </div>
                <div className="text-center">
                  <img src="/images/Fictional company logo.png" />
                </div>
                <div className="text-center">
                  <img src="/images/Fictional company logo.png" />
                </div> */}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CompanySlider;
