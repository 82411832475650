import React, { useEffect } from "react";

import Hero from "./Hero";
import CompanySlider from "../common/CompanySlider";
import OurAiTechnology from "./OurAiTechnology";
import Pioneer from "./Pioneer";
import Flexible from "./Flexible";
import Innovation from "./Innovation";
import Secure from "./Secure";
import Mission from "./Mission";
import Partner from "../common/Partner";


const Home = () => {
 
  
  return (
    <div>
        <Hero />
        {/* <CompanySlider /> */}
        <Partner />
        <OurAiTechnology />
        <Pioneer />
        <Flexible />
        <Innovation />
        <Secure />
        <Mission />
    </div>
  );
};

export default Home;
