import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <div className="container pt-0 pb-5">
        <footer className="pt-4 pb-5">
          <div className="d-flex justify-content-between align-items-center pt-4 pb-5 flex-wrap gap-3">
            <div className="d-flex align-items-center gap-2 ">
              <img src="/images/logo.svg" alt="logo" />
              {/* <h3>Ai Mining</h3> */}
            </div>
            <p>© 2024 AiMining Technologies. All rights reserved.</p>
          </div>
          <div className="row ">
            <div className="col-lg-8">
              <h6 className="footer-text">
                AiMining Technologies Inc is
                recognized as a Nvidia Inception Partner,
                as well as being chosen for the Microsoft start-up Founders Hub, stands as a
                pioneering force in Artificial Intelligence (AI) advancement.
              </h6>
            </div>

            {/* <div className="col-lg-2 col-md-3 col-6 pt-4 pt-md-0 ">
              <label htmlFor="">Product</label>
              <ul>
                <li>Embryo</li>
                <li>
                  <div className="d-flex align-items-center ">
                    Couples
                    <svg
                      width="41"
                      height="23"
                      viewBox="0 0 41 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g style={{ mixBlendMode: "multiply" }}>
                        <rect
                          x="0.306274"
                          y="0.519043"
                          width="40.3225"
                          height="21.8306"
                          rx="10.9153"
                          fill="#ECFDF3"
                        />
                        <path
                          d="M15.7306 7.0766V15.4343H14.5716L10.3234 9.30478H10.2458V15.4343H8.98484V7.0766H10.152L14.4043 13.2143H14.4818V7.0766H15.7306ZM20.1737 15.5608C19.5561 15.5608 19.0242 15.4289 18.5781 15.165C18.1346 14.8984 17.7918 14.5243 17.5497 14.0427C17.3103 13.5585 17.1905 12.9912 17.1905 12.341C17.1905 11.6989 17.3103 11.133 17.5497 10.6433C17.7918 10.1536 18.1292 9.77137 18.5617 9.49659C18.997 9.2218 19.5058 9.08441 20.088 9.08441C20.4417 9.08441 20.7845 9.14291 21.1164 9.25989C21.4483 9.37688 21.7462 9.56052 22.0101 9.81082C22.274 10.0611 22.4821 10.3862 22.6345 10.7862C22.7869 11.1834 22.863 11.6663 22.863 12.2349V12.6675H17.8802V11.7533H21.6673C21.6673 11.4323 21.602 11.148 21.4714 10.9004C21.3408 10.6501 21.1572 10.4529 20.9205 10.3087C20.6865 10.1645 20.4118 10.0924 20.0962 10.0924C19.7534 10.0924 19.4541 10.1767 19.1984 10.3454C18.9453 10.5114 18.7495 10.729 18.6107 10.9984C18.4747 11.265 18.4067 11.5547 18.4067 11.8676V12.5818C18.4067 13.0007 18.4801 13.3571 18.627 13.651C18.7767 13.9448 18.9848 14.1692 19.2514 14.3243C19.518 14.4767 19.8295 14.5528 20.1859 14.5528C20.4172 14.5528 20.628 14.5202 20.8185 14.4549C21.0089 14.3869 21.1735 14.2862 21.3123 14.1529C21.451 14.0196 21.5571 13.855 21.6306 13.6591L22.7855 13.8673C22.693 14.2073 22.527 14.5052 22.2876 14.761C22.0509 15.014 21.753 15.2112 21.3939 15.3527C21.0375 15.4915 20.6308 15.5608 20.1737 15.5608ZM25.5646 15.4343L23.72 9.16603H24.981L26.2094 13.7693H26.2706L27.503 9.16603H28.764L29.9883 13.7489H30.0495L31.2697 9.16603H32.5307L30.6902 15.4343H29.4456L28.1723 10.9086H28.0784L26.8052 15.4343H25.5646Z"
                          fill="#027A48"
                        />
                      </g>
                    </svg>
                  </div>
                </li>
                <li>More?</li>
              </ul>
            </div> */}
            {/* <div className="col-lg-2 col-md-3 col-6 pt-4 pt-md-0 ">
              <label htmlFor="">Company</label>
              <ul>
                <li>Embryo</li>
                <li>Couples</li>
                <li>More?</li>
              </ul>
            </div> */}
            <div className="col-lg-3 col-md-3 col-6 pt-4 pt-md-0 ">
              <label htmlFor="">Legal</label>
              <ul>
                <li>
                  <Link to="/contact">
                    Contact
                  </Link>
                </li>
                <li>
                  <Link to="/career">
                    Careers
                  </Link>
                </li>
                <li>
                  <Link to="/privacy">
                    Privacy
                  </Link>
                </li>
                <li>
                  <Link to="/terms-and-condition">
                    Terms & Condition
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-1 col-md-3  col-6 pt-4 pt-md-0 ">
              <label htmlFor="">Social</label>
              <ul>
                <li>
                  <Link target="_blank" to="https://www.linkedin.com/company/aimining-technologies/">
                    LinkedIn
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="https://www.youtube.com/@AiMiningTechnologies">
                    Youtube
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="https://twitter.com/i/flow/login?redirect_after_login=%2Faimining_tech">
                    Twitter
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="https://www.instagram.com/aimining_technologies/">
                    Instagram
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
