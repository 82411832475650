 import React from "react";
import { Link } from "react-router-dom";

const Partner = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-4 mb-4">
          <img src="/images/nvidia.png" className="w-100" />
        </div>
        <div className="col-lg-4 mb-4">
          <div className="partner text-center-div">
            <h4 className="purple-color">
              AI MINING POWERING INNOVATION WITH NVIDIA & MICROSOFT
            </h4>
            <p>
              Explore how AI Mining, a pioneering company, leverages the transformative power of NVIDIA Inception and the Microsoft Startup Founders Program.
            </p>
          </div>
        </div>
        <div className="col-lg-4 mb-4">
          <img src="/images/microsoft.png" className="w-100" />
        </div>
      </div>
    </div>
  );
};

export default Partner;
