import React from 'react'

const Secure = () => {
  return (
    <section className='Secure'>
      <div className="container">
        <div className='row'>
          <div className='col-lg-12'>
            <div className='heading'>
              <div>
                <h4>
                  Secure by Design, Trustworthy AI Powering Innovation with Uncompromising Security & Reliability
                </h4>
              </div>
              <div className='desc'>
                <p>
                  At AiMining Technologies, we're redefining the boundaries of AI innovation.
                  But our commitment to security and ethics is what truly sets us apart.
                </p>
                <img src='/images/innovation.svg' />
              </div>
            </div>
            <div className='content'>
              <div className='desc'>
                <p>
                  Our approach is rooted in the principles of Secure by Design, Trustworthy AI,
                  Zero Trust, and the rigorous standards set by NIST, ISO, and IEEE.
                  This  foundational framework ensures that security is woven into every aspect of  our products and processes.
                </p>
                <p>
                  We prioritize user safety, minimize attack surfaces,
                  and implement defensive in-depth strategies to create resilient systems that you can trust.
                  By adhering to  the principles of least privilege and continuous verification,
                  we eliminate blind  spots and safeguard against unauthorized access.
                </p>
                <p>
                  Our unwavering adherence to industry-leading protocols,
                  like NIST's  Cybersecurity Framework and ISO 42001 for AI management, guarantees
                  the  highest levels of security and compliance.
                  And our AI solutions are designed  to meet the stringent requirements of the IEEE Global Initiative on Ethics of
                  Autonomous and Intelligent Systems.
                </p>
              </div>
              <div className='md'>
                <img src='/images/innovationframe.svg' className='w-100' />
              </div>
              <div className='robot'>
                <p>
                  Experience the power of AI done right. Partner with AiMining Technologies
                  and unlock AI’s transformative potential without compromising on trust,  privacy, safety or reliability.
                </p>
                <div className='img'>
                <img src='/images/robot.svg' className='w-100' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Secure;