import React, { useRef } from "react";
import Hero from "./section/Hero";
import CompanySlider from "../common/CompanySlider";
import Partner from "../common/Partner";

const Vertical = () => {
  const valuesRef = useRef(null); // Reference to the Values component

  const scrollToValues = () => {
    // Scroll to the Values component
    valuesRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <Hero />
      {/* <CompanySlider /> */}
      <Partner />
      {/* <div className="hompage-hero" id="zIndexMob">
        <div className="home-inner">
          <div className="hero-section-new">
            <div id="homepage-bg-blur-anim">
              <div className="hero-blur-bg blur-jelly"></div>
              <div className="hero-blur-bg blur-icee"></div>
              <div className="hero-blur-bg blur-gumdrop"></div>
            </div>
          </div>
          <div className="container main-hero">
            <div
              className="row aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="col-lg-12 text-center">
                <span>Empowering Progress:</span>
                <h1>We believe that AI has the potential to </h1>
                <h2>
                  transform industries and improve the lives of people around
                  the world.{" "}
                </h2>
                <p className="mt-4">
                  At AiMining Technologies, we specialize in AI model
                  development with a focus on AI sovereignty. Our team of AI
                  experts has a deep understanding of the latest AI models and
                  standards, and we are dedicated to building AI models that are
                  responsible, trustworthy, and reliable, with privacy and data
                  privacy at the core of our business.
                </p>
                
                <div
                  className="d-flex align-items-center justify-content-between animated"
                  style={{ paddingBottom: "100px" }}
                ></div>
              </div>
            </div>
            <div class="toggle pb-3" onClick={scrollToValues}>
              <div class="toggle-button">
                <div class="ball"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="patch">
        <img src="/images/patch.png" alt="patch" />
      </div>
      </div> */}
      <div ref={valuesRef} className="">
        <section className="Models container technologies">
          <div class="Heading align-items-end">
            <div>
              <span className="d-flex gap-2 align-items-center left-span">
                <img src="/images/ellipse.png" alt="ellipses" />
                Tailored Industry Support
              </span>
              <h2>
                AI for Every Industry
              </h2>
            </div>
            <div>
              <p>
                Our AI models are designed to support a wide range of vertical
                focuses.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <div className="emp-img mb-4 mb-md-0">
                <img src="/images/education.png" alt="education" />
              </div>
            </div>
            <div className="offset-lg-1 col-lg-6 col-md-6 pt-lg-5 pt-0">
              <h3 className="pb-3 title">Education:</h3>
              <p className="desc">
                We build AI models that support personalized learning, improving
                student outcomes, and enhancing the overall educational
                experience. Our AI models can help educators identify areas where
                students need improvement, provide targeted feedback, and create a
                more engaging learning environment.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 pt-lg-5 pt-0 order-2 order-md-1 mt-3 mt-md-0">
              <h3 className="pb-3 title">Healthcare:</h3>
              <p className="desc">
                Our AI models can help healthcare providers improve patient
                outcomes, reduce costs, and enhance the overall quality of care.
                We build AI models that support medical diagnosis, drug discovery,
                and patient monitoring, among other applications. Our AI models
                can also support the development of precision medicine, medical
                image analysis, and personalized treatment plans.
              </p>
            </div>
            <div className="offset-lg-1 col-lg-5 col-md-6 order-1 order-md-2" >
              <div className="emp-img-2">
                <img src="/images/healtcare.png" alt="healtcare" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <div className="emp-img mb-4 mb-md-0">
                <img src="/images/security.png" alt="security" />
              </div>
            </div>
            <div className="offset-lg-1 col-lg-6 col-md-6 pt-lg-5 pt-0">
              <h3 className="pb-3 title">CyberSecurity:</h3>
              <p className="desc">
                We build AI models that help organizations protect against cyber
                threats and ensure the security of their digital assets. Our AI
                models can detect and respond to cyber-attacks, prevent data
                breaches, and ensure regulatory compliance. Our AI models support
                the development of zero-trust architecture and NIST protocols,
                post-quantum computing (PQC), and AI-enhanced threat intelligence.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 pt-lg-5 pt-0 order-2 order-md-1 mt-3 mt-md-0">
              <h3 className="pb-3 title">Food Security:</h3>
              <p className="desc">
                Our AI models support food security by optimizing agricultural
                practices, reducing waste, and improving supply chain management.
                We build AI models that help farmers make data-driven decisions,
                improve crop yields, and reduce environmental impact. Our AI
                models can also support the development of precision agriculture,
                crop monitoring, and food safety.
              </p>
            </div>
            <div className="offset-lg-1 col-lg-5 col-md-6 order-1 order-md-2">
              <div className="emp-img-2">
                <img src="/images/food.png" alt="food" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <div className="emp-img mb-4 mb-md-0">
                <img src="/images/cities.png" alt="cities" />
              </div>
            </div>
            <div className="offset-lg-1 col-lg-6 col-md-6 pt-lg-5 pt-0">
              <h3 className="pb-3 title">Smart Cities:</h3>
              <p className="desc">
                We build AI models that support the development of smart cities,
                where technology is used to improve the quality of life for
                citizens. Our AI models can support traffic management, energy
                management, and public safety, among other applications. Our AI
                models can also support the development of intelligent
                transportation systems, smart grids, and smart buildings.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 pt-lg-5 pt-0 order-2 order-md-1 mt-3 mt-md-0">
              <h3 className="pb-3 title">Ports and Shipping:</h3>
              <p className="desc">
                Our AI models support the efficient and secure operation of ports
                and shipping operations. We build AI models that support cargo
                tracking, route optimization, and risk management. Our AI models
                can also support the development of autonomous ships, predictive
                maintenance, and real-time tracking.
              </p>
            </div>
            <div className="offset-lg-1 col-lg-5 col-md-6 order-1 order-md-2">
              <div className="emp-img-2">
                <img src="/images/shipping.png" alt="shipping" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <div className="emp-img">
                <img src="/images/mining.png" alt="mining" />
              </div>
            </div>
            <div className="offset-lg-1 col-lg-6 col-md-6 pt-lg-5 pt-0">
              <h3 className="pb-3 mt-3 title">Mining:</h3>
              <p className="desc">
                We build AI models that support the mining industry, from
                exploration to extraction and processing. Our AI models can help
                mining companies optimize their operations, reduce costs, and
                improve safety. Our AI models can also support the development of
                autonomous mining equipment, predictive maintenance, and real-time
                monitoring.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Vertical;
