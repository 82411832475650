import React from "react";
import Slider from "react-slick";
import CompanySlider from "../common/CompanySlider";
// import { Link, animateScroll as scroll } from "react-scroll";


const Hero = () => {

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      const offsetTop = element.offsetTop;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  };
  return (
    <div>
      <div className="hompage-hero" id="zIndexMob">
        <div className="home-inner">
          <div className="hero-section-new">
            <div id="homepage-bg-blur-anim">
              <div className="hero-blur-bg blur-jelly"></div>
              <div className="hero-blur-bg blur-icee"></div>
              <div className="hero-blur-bg blur-gumdrop"></div>
            </div>
          </div>
          <div className="container main-hero">
            <div
              className="row aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="col-lg-12 text-center">
                {/* <span>Empowering Progress:</span> */}
                <h1>Forging the Frontier of Trustworthy </h1>
                <h2>High-Performance AI</h2>
                <p className="mt-4">
                Breakthrough Semiconductor Design, Flexible Infrastructure, and Principled<br /> AI Models for a Better Future
                </p>
                
                {/* <div className="text-center gap-2 d-flex justify-content-center mt-5">
                  <button className="create-button">GET START</button>
                  <button className="wallet-button">LEARN MORE</button>
                </div> */}
                <div
                  className="d-flex align-items-center justify-content-between animated"
                  style={{ paddingBottom: "100px" }}
                ></div>
              </div>
            </div>
            {/* <CompanySlider /> */}
           
          </div>
        </div>
        <div class="toggle" onClick={() => scrollToElement("first")}>
              <div class="toggle-button">
                <div class="ball"></div>
              </div>
            </div>
        <div className="patch">
        <img src="/images/patch.png" alt="patch" />
      </div>
      </div>
      
    </div>
  );
};

export default Hero;
