import axios from 'axios';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link, useParams } from "react-router-dom";
const token = process.env.REACT_APP_PRESS_API_TOKEN;
const url = process.env.REACT_APP_PRESS_API_ENDPOINT;

const BlogDetail = () => {

  const { id } = useParams();

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/api/ai-minings/${id}?populate=*`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data.data?.attributes || {});
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [token]);

  return (
    <>
      <div className="hompage-hero contact" id="zIndexMob">
        <div className="container">
          <div
            className="row aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <div className="col-lg-12 text-center">
              <h1>Our Blog</h1>
            </div>
          </div>

        </div>
      </div>
      <div className="blogs-main" style={{ overflow: "hidden" }}>
        <div className="container">
          <div className="row">

            <div className="offset-lg-1 col-lg-10">
              <div className="blog-detail">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="pt-4 pb-4" data-aos="fade-bottom">
                      <div className="row">
                        <div className="col-lg-12">
                          <h4
                           style={{
                            fontSize: "32px",
                            fontWeight: "700",
                            color: "#000",
                            fontFamily: "Inter",
                          }}
                            className="mb-4"
                          >
                            {!data.title && !isLoading ? <label style={{ width: '100%' }} className="text-center">No Blog Found</label> : ''}
                            {data.title}
                            {isLoading ? <Skeleton height={'60px'}/> : ''}
                          </h4>
                          {isLoading ? <Skeleton height={'200px'}/> : ''}
                          { data?.media?.data?.attributes?.url ? <img src={`${url}${data?.media?.data?.attributes?.url}`} className="mb-4 w-100" /> : ''}
                        </div>
                        <div className="col-lg-12">
                          <p className='mb-4' style={{fontFamily: "Inter"}}>
                            
                            {isLoading ? <Skeleton count={5} height={'20px'} style={{ marginTop: '20px' }} /> : ''}
                            {isLoading ? <Skeleton count={1} width={'30%'} height={'20px'} style={{ marginTop: '20px' }} /> : ''}
                            {isLoading ? <Skeleton count={1} width={'70%'} height={'20px'} style={{ marginTop: '20px' }} /> : ''}
                            {isLoading ? <Skeleton count={1} width={'40%'} height={'20px'} style={{ marginTop: '20px' }} /> : ''}
                            {isLoading ? <Skeleton count={1} width={'30%'} height={'20px'} style={{ marginTop: '20px' }} /> : ''}
                            {isLoading ? <Skeleton count={1} width={'55%'} height={'20px'} style={{ marginTop: '20px' }} /> : ''}
                            {isLoading ? <Skeleton count={1} width={'65%'} height={'20px'} style={{ marginTop: '20px' }} /> : ''}
                          

                            {data.description?.map(({children}, index) => (
                              <p key={index}>{children?.[0].text}</p>
                            ))}
                          </p>
                        </div>
                        <div className="col-lg-12">
                          <div className="d-flex align-items-center gap-4 pt-2 pb-2">
                            {  data?.createdAt && <span>{moment(data?.createdAt).format("MMMM DD, YYYY")}</span> }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default BlogDetail;
