import React from 'react'

const Mission = () => {
  return (
    <section className='Mission'>
      <div className="container">
        <div className='row'>
          <div className='col-lg-12'>
            <div className='content'>
              <h4>
                <span className='purple-color'>Join Our</span> in Shaping the Future of Trustworthy AI
              </h4>
              <p>
                If you're passionate about using your skills and expertise to create a better, more secure  future through AI,
                we invite you to join the AiMining Technologies team. Whether you're an  experienced AI researcher, a talented engineer,
                or a brilliant data scientist, we have  opportunities across our three core pillars of innovation:
                AI models and applications,  semiconductor design, and flexible infrastructure.
              </p>
              <p>
                Together, we will redefine the boundaries of what's possible in the world of AI, developing
                solutions that not only deliver unparalleled performance but also uphold the highest  standards of security, privacy, and ethics.
              </p>
              <p>
                Together, we are redefining the boundaries of what's possible in AI,
                creating cutting-edge  solutions that not only deliver unparalleled performance but also uphold the highest
                standards of trust , security and responsibility.
              </p>
              <p>
                Take the first step towards a rewarding career at the forefront of trustworthy AI innovation.
                Explore our open positions and join us in shaping a future where the transformative power
                of AI is harnessed responsibly and for the greater good.
              </p>
              <div class="input-group custom-input mb-3">
                <input type="text" class="form-control" placeholder="Email." />
                  <div class="input-group-append">
                    <button class="header-button" type="button">Join Us</button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Mission;