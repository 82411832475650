import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
const token = process.env.REACT_APP_PRESS_API_TOKEN;
const url = process.env.REACT_APP_PRESS_API_ENDPOINT;
// import BlogSection from "../Home/BlogSection";
function NewsList() {
    const [data, setData] = useState([]);
    const [isLoading, setIsloading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${url}/api/ai-minings?populate=*`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                console.log("Bilal", response)
                setData(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            setIsloading(false);
        };

        fetchData();
    }, [token]);
    return (
        <>
            {/* <Hero /> */}
            <div className="hompage-hero contact" id="zIndexMob">
                <div className="container">
                    <div
                        className="row aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-duration="2000"
                    >
                        <div className="col-lg-12 text-center">
                            <h1>Our Blogs List</h1>
                        </div>
                    </div>

                </div>
            </div>
            <section className="PressList listing mt-5">
                <div className="container">
                    <div className="row">
                        {data.map(press => (
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="content">
                                    <div>
                                        {press.attributes.media?.data?.attributes?.url ? <img src={`${url}${press.attributes.media?.data?.attributes?.url}`} className="w-100" /> : ''}
                                        {isLoading ? <Skeleton count={3}/> : ''}
                                    </div>
                                    <div>
                                        <label className="mt-3 mb-2" style={{color:"#000"}}>
                                            {moment(press.attributes.createdAt).format("MMMM DD, YYYY")}
                                        </label>
                                        <p className='mb-3' style={{color:"#000"}}>
                                            {press.attributes.title.slice(0,100) + "..."}
                                        </p>
                                        <Link className='purple-color' to={`/news-detail/${press.id}`}>
                                            learn more
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            {/* <BlogSection /> */}
        </>
    )
}

export default NewsList;