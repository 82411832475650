import React from "react";

const MakeImpact = () => {
  return (
    <div className="main-impact mt-5">
      <div className="container">
        <h2>Make and Impact :</h2>
        <p className="px-5 pt-4">
          We are a creative team with high scientific standards. We make
          compute efficient,
          <br /> useful and powerful AI models with both a strong research focus
          and
          <br /> a fast-paced entrepreneurial mindset. Join us and be a part of
          a team
          <br />
          that is dedicated to building responsible AI systems.
        </p>
        <div className="text-center-div test">
          <button className="header-button learn-more">
            Join Us{" "}
            {/* <svg
              width="10"
              height="9"
              viewBox="0 0 10 9"
              fill="none"
              style={{marginLeft:"20px"}}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.39403 5.21302H7.58427L4.87985 7.91744C4.66372 8.13357 4.66372 8.48825 4.87985 8.70438C5.09598 8.92051 5.44512 8.92051 5.66125 8.70438L9.31333 5.05231C9.52946 4.83617 9.52946 4.48704 9.31333 4.27091L5.66679 0.613271C5.45066 0.397139 5.10152 0.397139 4.88539 0.613271C4.66926 0.829403 4.66926 1.17854 4.88539 1.39467L7.58427 4.10465H1.39403C1.08923 4.10465 0.839844 4.35403 0.839844 4.65884C0.839844 4.96364 1.08923 5.21302 1.39403 5.21302Z"
                fill="white"
              />
            </svg>{" "} */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MakeImpact;
