import React, { useEffect } from "react";

import { HashRouter as BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from "./component/Layouts/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./component/Layouts/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import Home from "./component/Home/Home";
import Company from "./component/Company/Company";
import Technologiess from "./component/Technology/Technologiess";
import Vertical from "./component/Vertical/Vertical";
import Contact from "./component/Contact/Contact";
import News from "./component/News/News";
import NewsList from "./component/News/List/NewsList";
import BlogDetail from "./component/Home/BlogDetail";
import Career from "./component/career/Career";
import Terms from "./component/Pages/Terms";
import Privacy from "./component/Pages/Privacy";
import ScrollUp from "./component/ScrollUp/ScrollUp";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


function App() {
  useEffect(() => {
    AOS.init();
  }, []);


  

  return (
    <>
      <SkeletonTheme baseColor="#ece0f4" highlightColor="#c1bde6">
      <BrowserRouter>
        <Header />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/company" element={<Company />} />
            <Route path="/technology" element={<Technologiess />} />
            <Route path="/verticals" element={<Vertical />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/career" element={<Career />} />
            <Route path="/news" element={<News />} />
            <Route path="/news-list" element={<NewsList />} />
            <Route path="/news-detail/:id" element={<BlogDetail />} />
            <Route path="/terms-and-condition" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
        <Footer />
        <ScrollUp />
      </BrowserRouter>
     </SkeletonTheme>
    </>
  );
}

export default App;
