import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const { pathname } = useLocation();
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }, [pathname]);

    return <></>
}