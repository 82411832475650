import React from 'react'

const OurAiTechnology = () => {
  return (
    <div className='ai-technology' id='first'>
      <div className="container">
        <div className='row'>
          <div className='col-12'>
            <div className='d-flex align-items-center gap-5 holistic'>
              <img src="/images/ai-frame.png" alt="ai-frame" />
              <h2>Our Holistic AI Technology </h2>
            </div>
            <h2 className='text-end'>AI Models and Core Applications<span className='one'>1</span></h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-5 col-sm-12'>
            <div className='left-content'>
              <h4 className='purple-color'>
                Frontier Performance:&nbsp;
                <span>
                  Our state-of-the-art AI models deliver unparalleled speed and  reasoning capabilities,
                  setting new benchmarks for industry performance.
                  Designed to be  highly customizable and unbiased. Our models provide granular control over
                  moderation,  making them the ideal foundation for a wide range of AI-powered applications.
                </span>
              </h4>
            </div>
          </div>
          <div className='col-lg-5 col-sm-12'>
            <div className='right-content'>
              <h4 className='blue-color'>
                Vertical-Centric AI Solutions:&nbsp;
                <span>
                  We have developed specialized AI models and applications  tailored to the unique needs of our target sectors,
                  including education, healthcare,  cybersecurity, and more.
                  By deeply integrating our technology into the core workflows of  these industries, we unlock transformative efficiencies and insights.
                </span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurAiTechnology