function Privacy() {
  return (
    <>
      <div className="hompage-hero contact" id="zIndexMob">
        <div className="container">
          <div
            className="row aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <div className="col-lg-12 text-center">
              <h1>Privacy Policy</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container" id="getintouch">
        <div className="row">
          <div className="col-12">
            <div className="Privacy">
              <p>
                At Ai Mining Technologies, accessible from
                https://www.AiMining.com/, one of our main priorities is the
                privacy of our visitors. This Privacy Policy document contains
                types of information that is collected and recorded by Ai Mining
                Technologies and how we use it.
              </p>
              <p>
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us.
              </p>
              <p>
                This Privacy Policy applies only to our online activities and is
                valid for visitors to our website with regards to the
                information that they shared and/or collect in Ai Mining
                Technologies. This policy does not apply to any information
                collected offline or via channels other than this website.
              </p>
              <h3>Consent</h3>
              <p>
                By using our website, you hereby consent to our Privacy Policy
                and agree to its terms.
              </p>
              <h3>Information we collect</h3>
              <p>
                The personal information that you are asked to provide, and the
                reasons why you are asked to provide it, will be made clear to
                you at the point we ask you to provide your personal
                information.
              </p>
              <p>
                If you contact us directly, we may receive additional
                information about you such as your name, email address, phone
                number, the contents of the message and/or attachments you may
                send us, and any other information you may choose to provide.
              </p>
              <p>
                When you register for an Account, we may ask for your contact
                information, including items such as name, company name,
                address, email address, and telephone number.
              </p>
              <h3>How we use your information</h3>
              <p>
                We use the information we collect in various ways, including to:
              </p>
              <ul>
                <li>Provide, operate, and maintain our website</li>
                <li>Improve, personalize, and expand our website</li>
                <li>
                  Understand and analyze how you use our website
                  <br />
                  Develop new products, services, features, and functionality
                </li>
                <li>
                  Communicate with you, either directly or through one of our
                  partners, including for customer service, to provide you with
                  updates and other information relating to the website, and for
                  marketing and promotional purposes
                </li>
                <li>Send you emails</li>
                <li>Find and prevent fraud</li>
              </ul>
              <h3>Log Files</h3>
              <p>
                Ai Mining Technologies follows a standard procedure of using log
                files. These files log visitors when they visit websites. All
                hosting companies do this and are a part of hosting services’
                analytics. The information collected by log files includes
                internet protocol (IP) addresses, browser type, Internet Service
                Provider (ISP), date and time stamp, referring/exit pages, and
                possibly the number of clicks. These are not linked to any
                personally identifiable information. The purpose of the
                information is for analyzing trends, administering the site,
                tracking users’ movement on the website, and gathering
                demographic information.
              </p>
              <h3>Cookies and Web Beacons</h3>
              <p>
                Like any other website, Ai Mining Technologies uses ‘cookies’.
                These cookies are used to store information including visitors’
                preferences, and the pages on the website that the visitor
                accessed or visited. The information is used to optimize the
                users’ experience by customizing our web page content based on
                visitors’ browser type and/or other information.
              </p>
              <h3>Advertising Partners Privacy Policies</h3>
              <p>
                You may consult this list to find the Privacy Policy for each of
                the advertising partners of Ai Mining&nbsp;Technologies.
              </p>
              <p>
                Third-party ad servers or ad networks uses technologies like
                cookies, JavaScript, or Web Beacons that are used in their
                respective advertisements and links that appear on
                Ai Mining&nbsp;Technologies, which are sent directly to users’
                browser. They automatically receive your IP address when this
                occurs. These technologies are used to measure the effectiveness
                of their advertising campaigns and/or to personalize the
                advertising content that you see on websites that you visit.
              </p>
              <p>
                Note that Ai Mining Technologies has no access to or control over
                these cookies that are used by third-party advertisers.
              </p>
              <h3>Third-Party Privacy Policies</h3>
              <p>
                Ai Mining Technologies Privacy Policy does not apply to other
                advertisers or websites. Thus, we are advising you to consult
                the respective Privacy Policies of these third-party ad servers
                for more detailed information. It may include their practices
                and instructions about how to opt out of certain options.
              </p>
              <p>
                You can choose to disable cookies through your individual
                browser options. To know more detailed information about cookie
                management with specific web browsers, it can be found at the
                browsers’ respective websites.
              </p>
              <h3>CCPA Privacy Rights (Do Not Sell My Personal Information)</h3>
              <p>
                Under the CCPA, among other rights, California consumers have
                the right to:
              </p>
              <p>
                Request that a business that collects a consumer’s personal data
                disclose the categories and specific pieces of personal data
                that a business has collected about consumers.
              </p>
              <p>
                Request that a business deletes any personal data about the
                consumer that a business has collected.
              </p>
              <p>
                Request that a business that sells a consumer’s personal data,
                not sell the consumer’s personal data.
              </p>
              <p>
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
              </p>
              <h3>GDPR Data Protection Rights</h3>
              <p>
                We would like to make sure you are fully aware of all of your
                data protection rights. Every user is entitled to the following:
              </p>
              <p>
                The right to access – You have the right to request copies of
                your personal data. We may charge you a small fee for this
                service.
              </p>
              <p>
                The right to rectification – You have the right to request that
                we correct any information you believe is inaccurate. You also
                have the right to request that we complete the information you
                believe is incomplete.
              </p>
              <p>
                The right to erasure – You have the right to request that we
                erase your personal data, under certain conditions.
              </p>
              <p>
                The right to restrict processing – You have the right to request
                that we restrict the processing of your personal data, under
                certain conditions.
              </p>
              <p>
                The right to object to processing – You have the right to object
                to our processing of your personal data, under certain
                conditions.
              </p>
              <p>
                The right to data portability – You have the right to request
                that we transfer the data that we have collected to another
                organization, or directly to you, under certain conditions.
              </p>
              <p>
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
              </p>
              <h3>Children’s Information</h3>
              <p>
                Another part of our priority is adding protection for children
                while using the internet. We encourage parents and guardians to
                observe, participate in, and/or monitor and guide their online
                activity.
              </p>
              <p>
                Ai Mining&nbsp;Technologies does not knowingly collect any
                Personal Identifiable Information from children under the age of
                13. If you think that your child provided this kind of
                information on our website, we strongly encourage you to contact
                us immediately and we will do our best efforts to promptly
                remove such information from our records.
              </p>
              <h4>COOKIES</h4>
              <p>
                When you first visit Ai Mining Technologies Technologies Inc’s
                (“Ai Mining”, “Ai Mining Technologies” or “The Company”) website,
                you will be notified that we are using cookies with a link to
                our privacy policy page. A cookie is a short text file that may
                be stored on your hard drive when you visit a website. We use
                the following types of cookies on this website:
              </p>
              <p>&nbsp;</p>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Cookie</strong>
                      </td>
                      <td>
                        <strong>Purpose</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Google Analytics &amp; Mamoto</td>
                      <td>
                        These cookies are used to collect information about how
                        visitors use our site. We use the information to compile
                        reports and help us improve the site. The cookies
                        collect information in an anonymous form, including the
                        number of visitors to the site, where visitors have come
                        to the site from and the pages they visited.
                      </td>
                    </tr>
                    <tr>
                      <td>Display and preference settings cookies</td>
                      <td>
                        For example, if you changed the size of the text on our
                        website when you last visited, a cookie will remember
                        that so that you don’t need to do it again.
                      </td>
                    </tr>
                    <tr>
                      <td>Session Cookies</td>
                      <td>
                        To identify and maintain a browsing session, and to
                        remember whether you have rejected cookies through your
                        browser settings.
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        Cookies that track whether a social networking option
                        has been used to forward material from our website (so
                        that we know which social networking tools are in use)
                      </td>
                    </tr>
                    <tr>
                      <td>SlideShare</td>
                      <td>
                        The cookies are used to collect information about how
                        visitors view our PowerPoint. We use this information to
                        compile reports and evaluate whether or not we should
                        create PowerPoints in different languages.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p>
                These cookies do not collect or track any personal data or
                information about you as an individual. Instead, they are
                focused on how the website is used and seek to enhance the
                accessibility of the website.
              </p>
              <p>
                We also log your domain and IP address automatically when you
                visit this website. This data identifies the computer that you
                are using to view this website and your approximate geographic
                location. Again, we do this to track usage patterns.
              </p>
              <p>
                Note that most web browsers allow some control of cookies
                through browser settings. If you delete or block our cookies,
                some parts of the website may not work properly, because some of
                our cookies are necessary for the operation of this website.
              </p>
              <p>
                This Cookies Statement places all users of this website on
                notice about our use of cookies. Your continued use of this
                website confirms to us that you are aware of our use of cookies
                and have no objection to this.
              </p>
              <h3>PERSONAL DATA</h3>
              <p>
                On our website, we provide an option to subscribe to our email
                list by filling out a form to receive news releases or other
                information concerning Ai Mining Technologies. By providing your
                e-mail address on this form, you are consenting to receive this
                information.&nbsp; However, you may withdraw your consent at any
                time.
              </p>
              <p>
                The information we ask for on this form is simply your name
                (first and last) and email address.&nbsp;&nbsp; Personal data
                that is collected through the subscribe form on our website is
                shared with the Company’s external service providers who assist
                us with our services and functions. Personal data that is
                collected through this website may be stored and processed in
                any country where the company or its external service providers
                operate.
              </p>
              <p>
                Another example of how you may choose to provide personal data
                to us through this website is by sending us an email with a
                question or comment.&nbsp;&nbsp;We will process the personal
                data that you provide through this website to answer your query
                and if relevant, to manage our business relationship with you or
                your company.
              </p>
              <p>
                We take all reasonable steps to protect the security of
                information (including personal data) that is provided by you or
                exchanged with you through this website. Our security measures
                include using firewalls, intrusion detection systems and virus
                scanning tools to protect against unauthorized persons and
                viruses from accessing the information that you provide to us,
                and we to you. However, please be aware that there are inherent
                risks in transmitting information by use of the Internet and
                other online or electronic transmission systems and that we
                cannot guarantee the security of information transmitted in this
                way.
              </p>
              <p>
                This website may contain links to third-party websites. Before
                providing personal data to third-party websites, we recommend
                you examine the privacy policies on those websites. Ai Mining
                Technologies is not responsible for the privacy practices on
                third-party websites.
              </p>
              <p>
                Ai Mining Technologies website, external service providers and
                linked third-party sites are not for use by children under the
                age of 16 years and the Company does not knowingly collect,
                store, share or use the personal data of children under 16
                years. If you are under the age of 16 years, please do not
                provide any personal data, even if prompted by the sites to do
                so. If you are under the age of 16 years and you have provided
                personal data, please ask your parent(s) or guardian(s) to
                notify the Company and we will delete all such personal data.
              </p>
              <p>To change cookie settings for this website:</p>
              <p>
                Your web browser may allow some control of cookies through the
                browser settings. Learn more about cookies, including how to see
                what cookies have been set and how to manage and delete them,
                at&nbsp;www.allaboutcookies.org.
              </p>
              <p>
                There are no essential cookies required for this site, however,
                allowing cookies will improve the experience.
              </p>
              <p>
                To opt-out of being tracked by Google Analytics across all
                websites visit{" "}
                <a
                  href="https://tools.google.com/dlpage/gaoptout"
                  target="_blank"
                  rel="noopener"
                >
                  tools.google.com/dlpage/gaoptout
                </a>
              </p>
              <p>
                For more information contact us at{" "}
                <a
                  href="mailto:legal@AiMining.com"
                  target="_blank"
                  rel="noopener"
                >
                  legal@AiMining.com
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Privacy;
