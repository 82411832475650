import React from "react";

const Hero = () => {
  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      const offsetTop = element.offsetTop;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  };
  return (
    <div>
      <div className="hompage-hero" id="zIndexMob">
        <div className="home-inner">
          <div className="hero-section-new">
            <div id="homepage-bg-blur-anim">
              <div className="hero-blur-bg blur-jelly"></div>
              <div className="hero-blur-bg blur-icee"></div>
              <div className="hero-blur-bg blur-gumdrop"></div>
            </div>
          </div>
          <div className="container main-hero">
            <div
              className="row aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="col-lg-12 text-center">
                <h1>Advancing AI with </h1>
                <h2>Human-Centered Principles</h2>
                <p className="mt-4">
                  Building trustworthy, reliable, and private AI systems<br /> for a better tomorrow.
                </p>
                <div
                  className="d-flex align-items-center justify-content-between animated"
                  style={{ paddingBottom: "100px" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="toggle" onClick={() => scrollToElement("first")}>
              <div class="toggle-button">
                <div class="ball"></div>
              </div>
            </div>
        <div className="patch">
          <img src="/images/patch.png" alt="patch" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
