import React from 'react'

const Innovation = () => {
  return (
    <section className='Innovation'>
      <div className="container">
        <div className='row'>
          <div className='col-lg-12'>
            <div className='content'>
              <h4>
                Through this three-pronged approach to&nbsp;
                <span>innovation,</span> AiMining Technologies is  empowering businesses and
                organizations of all sizes to harness the transformative
                potential of AI.
                By combining <span>cutting-edge models</span>,
                specialized <span>semiconductor design,</span>
                and<span> flexible infrastructure,</span> we are redefining the boundaries of what's possible in the  AI landscape.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Innovation