import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div style={{top:"20px"}} className="navbar navbar-expand-lg navbar-light fixed-top">

      <Navbar expand="lg">
        <Container>
          <div className="header">
            <div className="justify-content-between d-flex ">
              <Navbar.Brand>
                <Link to="/">
                  <img src="/images/logo.svg" alt="logo" />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </div>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto">
                {/* <Link to="" className="nav-link">Research</Link> */}
                <Link to="/technology" className="nav-link">Technology</Link>
                <Link to="/company" className="nav-link">Company</Link>
                <Link to="/news" className="nav-link">News</Link>
                <Link to="/verticals" className="nav-link">Verticals</Link>
                <Link to="/career" className="nav-link">Careers</Link>
                <div className="mobile">
                  <Link to="/contact" className="header-button">Contact Us</Link>
                </div>
              </Nav>
            </Navbar.Collapse>
            <div className="desktop">
              <Link to="/contact" className="header-button">Contact Us</Link>
            </div>
          </div>
        </Container>
      </Navbar>
    </div>

  );
};

export default Header;
