import Spline from "../common/Spline";
import data from "./data/data.json";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

const token = process.env.REACT_APP_PRESS_API_TOKEN;
const url = process.env.REACT_APP_PRESS_API_ENDPOINT;

const BlogSection = () => {
  // const [users, setUsers] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   setUsers(data);
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/api/ai-minings?populate=*`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [token]);

  return (
    <div className="blogs-main" style={{ overflow: "hidden" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="inner-blogs">
              <div className="row">
                {data.slice(0, 1).map((press) => (
                  <div className="col-lg-12" data-aos="zoom-out-up">
                    <span className="d-flex gap-2 align-items-center left-span">
                      <img src="/images/ellipse.png" alt="ellipses" />
                      Company
                    </span>
                    <h2 className="pt-3 pb-3">
                      {isLoading ? (
                        <Skeleton count={3} />
                      ) : (
                        data?.[0].attributes.title.slice(0, 80)
                      )}
                    </h2>
                    <img
                      style={{ width: "50%", margin: "auto", display: "flex" }}
                      src={`${url}${press.attributes.media?.data?.attributes?.url}`}
                      className="my-4"
                    />
                    <p>
                      {isLoading ? (
                        <Skeleton count={6} />
                      ) : (
                        data?.[0].attributes.shortDescription
                      )}
                      {/* {press.attributes.description?.[0].children?.[0].text} */}
                    </p>
                    <div className="d-flex gap-4 pt-4">
                      {isLoading ? (
                        <Skeleton count={1} />
                      ) : (
                        <span>
                          {moment(data?.[0].attributes.createdAt).format(
                            "MMMM DD, YYYY"
                          )}
                        </span>
                      )}
                    </div>
                    {isLoading ? (
                      <Skeleton count={1} />
                    ) : (
                      <Link
                        to={`/news-detail/${data?.[0].id}`}
                        className="read-news d-block mt-4"
                      >
                        Read news
                      </Link>
                    )}
                  </div>
                ))}
                {/* <div className="col-lg-5" data-aos="zoom-out-up">
                  <span className="d-flex gap-2 align-items-center left-span">
                    <img src="/images/ellipse.png" alt="ellipses" />
                    {data.length}
                  </span>
                  <h2 className="pt-3 pb-3">
                    AiMining Breaks Ground with Revolutionary AI, Paving the
                    Path for Future Technological Frontiers.{" "}
                  </h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc ac ornare tortor. Class aptent taciti sociosqu ad
                    litora torquent per conubia nostra, per inceptos himenaeos.
                  </p>
                  <div className="d-flex gap-4 pt-4">
                    <span>Dec 19, 2023</span>
                    <span>
                      <svg
                        width="7"
                        height="8"
                        viewBox="0 0 7 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.576 7.272C1.816 7.272 0.408 5.864 0.408 4.104C0.408 2.344 1.816 0.936 3.576 0.936C5.336 0.936 6.744 2.344 6.744 4.104C6.744 5.864 5.336 7.272 3.576 7.272Z"
                          fill="#91918D"
                        />
                      </svg>
                      {"" + ""} 1 min read
                    </span>
                  </div>
                  <button className="read-news mt-4">Read news</button>
                </div> */}
                {/* <div className="col-lg-7">
                  <iframe src="https://app.spline.design/file/f0a31a6b-72ef-488a-b68a-d6f2080f3e54"></iframe>
                  <img  data-aos="zoom-out" className="w-100" src="https://app.spline.design/file/f0a31a6b-72ef-488a-b68a-d6f2080f3e54" alt="brain" />
                  <video>
                    <source src="https://app.spline.design/file/f0a31a6b-72ef-488a-b68a-d6f2080f3e54" />
                  </video>
                  { isLoading ? <Skeleton style={{ height: '400px' }} /> : 
                  <Spline />
                  <img src="/images/brain.gif" className="w-100" />
                  }
                </div> */}
                {/* <div className="row pt-5">
                  <div className="col-lg-4" data-aos="fade-up">
                    <div className="p-2">
                      <div className="d-flex align-items-center gap-2 ">
                        <svg
                          width="14"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.99999 1.66672V14.3327M12.333 7.99972C12.333 11.2717 8.76399 13.6517 7.46499 14.4097C7.31799 14.4957 7.24399 14.5397 7.13999 14.5607C7.04746 14.5781 6.95252 14.5781 6.85999 14.5607C6.75599 14.5387 6.68199 14.4957 6.53499 14.4097C5.23499 13.6517 1.66699 11.2717 1.66699 7.99972V4.81172C1.66699 4.27872 1.66699 4.01172 1.75399 3.78272C1.83099 3.58072 1.95599 3.39972 2.11799 3.25672C2.30199 3.09472 2.55199 3.00072 3.05099 2.81372L6.62499 1.47372C6.76399 1.42172 6.83299 1.39572 6.90499 1.38572C6.968 1.37664 7.03198 1.37664 7.09499 1.38572C7.16699 1.39572 7.23499 1.42172 7.37499 1.47372L10.949 2.81372C11.449 3.00072 11.698 3.09372 11.882 3.25672C12.044 3.39972 12.169 3.58072 12.246 3.78272C12.333 4.01272 12.333 4.27872 12.333 4.81172V7.99972Z"
                            stroke="#1A2B3B"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Carrier screening
                      </div>
                      <p>
                        Take the reins on which risk profiles you want to
                        support.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4" data-aos="fade-up">
                    <div className="p-2">
                      <div className="d-flex align-items-center gap-2">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.871 11.354L2.908 11.294C3.84256 9.80875 4.33668 8.08886 4.333 6.33403C4.333 5.6267 4.61399 4.94833 5.11415 4.44818C5.61431 3.94802 6.29267 3.66703 7 3.66703C7.70733 3.66703 8.38569 3.94802 8.88585 4.44818C9.38601 4.94833 9.667 5.6267 9.667 6.33403C9.667 7.01103 9.62 7.67903 9.531 8.33403M8.12 12.895C8.528 12.081 8.863 11.224 9.115 10.332M11.675 11.087C12.105 9.57703 12.335 7.98103 12.335 6.33203C12.3353 5.39569 12.0891 4.47578 11.6211 3.66478C11.1531 2.85379 10.4799 2.1803 9.66902 1.71204C8.85818 1.24378 7.93835 0.997255 7.00201 0.997253C6.06567 0.997252 5.14584 1.24377 4.335 1.71203M1 9.24403C1.44034 8.33724 1.66844 7.34208 1.667 6.33403C1.667 5.36303 1.927 4.45203 2.38 3.66803M7 6.33303C7 8.67803 6.327 10.866 5.165 12.714"
                            stroke="#1A2B3B"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Cancer screening
                      </div>
                      <p>
                        Identity requirements that fit your customers and
                        business.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4" data-aos="fade-up">
                    <div className="p-2">
                      <div className="d-flex align-items-center gap-2">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.333 7.667V10.333M9.667 6.333V10.333M7 3.667V10.333M4.2 13H9.8C10.92 13 11.48 13 11.908 12.782C12.2843 12.5903 12.5903 12.2843 12.782 11.908C13 11.48 13 10.92 13 9.8V4.2C13 3.08 13 2.52 12.782 2.092C12.5903 1.71569 12.2843 1.40974 11.908 1.218C11.48 1 10.92 1 9.8 1H4.2C3.08 1 2.52 1 2.092 1.218C1.71569 1.40974 1.40974 1.71569 1.218 2.092C1 2.52 1 3.08 1 4.2V9.8C1 10.92 1 11.48 1.218 11.908C1.40974 12.2843 1.71569 12.5903 2.092 12.782C2.52 13 3.08 13 4.2 13Z"
                            stroke="#1A2B3B"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Prenatal screening
                      </div>
                      <p>Run your own transaction monitoring in-house.</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog-list">
              <div className="row">
                <div className="d-flex  align-items-center justify-content-between">
                  <h4>Recommended</h4>{" "}
                  <div className="view">
                    <Link
                      className="read-news"
                      to="/news-list"
                      style={{ textDecoration: "none" }}
                    >
                      View all
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  {/* <div className="block-image" data-aos="fade-left">
                    <img src="/images/Whoweare.png" alt="Whoweare" />
                    <div className="block-text">
                      <div className="d-flex align-items-center gap-4 ">
                        <span>Company</span>
                        <span>Feb 16,2024</span>
                      </div>
                      <h5>Lorem ipsum dolor sit amet consectetur.</h5>
                    </div>
                  </div> */}
                  {isLoading ? (
                    <Skeleton
                      count={4}
                      style={{ marginTop: "30px", height: "150px" }}
                    />
                  ) : (
                    ""
                  )}
                  {!isLoading && data.length < 2 ? (
                    <label
                      className="text-center"
                      style={{ width: "100%", marginTop: "30%", color: "gray" }}
                    >
                      No recomendations found
                    </label>
                  ) : (
                    ""
                  )}
                  {data.slice(1, 4).map((press) => (
                    <div className="comments pt-4 pb-4" data-aos="fade-left">
                      <div className="row">
                        <div className="col-lg-10">
                          <div className="d-flex align-items-center gap-4 pt-2 pb-2">
                            <h4>Company</h4>
                            <span>
                              {moment(press.attributes.createdAt).format(
                                "MMMM DD, YYYY"
                              )}
                            </span>
                          </div>
                          <p>
                            <Link to={`/news-detail/${press.id}`}>
                              {press.attributes.title.slice(0, 250)}
                            </Link>
                            {/* <Link to={`/news-detail/${press.id}`}>Learn more</Link> */}
                          </p>
                        </div>
                        <div className="col-lg-2">
                          <img
                            src={`${url}${press.attributes.media?.data?.attributes?.url}`}
                            className="mt-4 w-100"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* <div className="comments pt-4 pb-4" data-aos="fade-left">
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="d-flex align-items-center gap-4 pt-2 pb-2">
                          <h4>Compnay</h4>
                          <span>Feb 16,2024</span>
                        </div>
                        <p>
                          Lorem ipsum, dolor sit amet consectetur adipisicing
                          elit. Impedit, at quod mollitia laudantium voluptatum
                          optio velit deleniti distinctio debitis officia alias
                          earum aut vel eius numquam nesciunt? Odit, beatae
                          voluptates!
                        </p>
                      </div>
                      <div className="col-lg-2">
                        <img
                          className="mt-4"
                          src="/images/comment-img.png"
                          alt="comment"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="comments pt-4 pb-4" data-aos="fade-left">
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="d-flex align-items-center gap-4 pt-2 pb-2">
                          <h4>Compnay</h4>
                          <span>Feb 16,2024</span>
                        </div>
                        <p>
                          Lorem ipsum, dolor sit amet consectetur adipisicing
                          elit. Impedit, at quod mollitia laudantium voluptatum
                          optio velit deleniti distinctio debitis officia alias
                          earum aut vel eius numquam nesciunt? Odit, beatae
                          voluptates!
                        </p>
                      </div>
                      <div className="col-lg-2">
                        <img
                          className="mt-4"
                          src="/images/comment-img.png"
                          alt="comment"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="comments pt-4 pb-4" data-aos="fade-left">
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="d-flex align-items-center gap-4 pt-2 pb-2">
                          <h4>Compnay</h4>
                          <span>Feb 16,2024</span>
                        </div>
                        <p>
                          Lorem ipsum, dolor sit amet consectetur adipisicing
                          elit. Impedit, at quod mollitia laudantium voluptatum
                          optio velit deleniti distinctio debitis officia alias
                          earum aut vel eius numquam nesciunt? Odit, beatae
                          voluptates!
                        </p>
                      </div>
                      <div className="col-lg-2">
                        <img
                          className="mt-4"
                          src="/images/comment-img.png"
                          alt="comment"
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSection;
